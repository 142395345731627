/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'link': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" d="M3.25 7.853l3.845-3.875a.719.719 0 011.232.514.73.73 0 01-.212.514L4.271 8.881a.72.72 0 01-1.02 0 .727.727 0 01-.157-.793.727.727 0 01.157-.235zm2.225 1.013l-1.093 1.1a1.591 1.591 0 01-2.21 0 1.575 1.575 0 01-.457-1.113 1.585 1.585 0 01.458-1.113l1.092-1.1a.727.727 0 000-1.028.72.72 0 00-1.02 0l-1.092 1.1a3.04 3.04 0 00-.88 2.141c0 .803.316 1.574.88 2.142a2.993 2.993 0 002.125.886c.797 0 1.56-.319 2.124-.886l1.093-1.101a.727.727 0 00.156-.792.727.727 0 00-.666-.449.716.716 0 00-.51.213zm.486-7L4.87 2.968a.727.727 0 00-.156.792.727.727 0 00.666.449.716.716 0 00.51-.213l1.092-1.1a1.561 1.561 0 011.105-.462 1.552 1.552 0 011.104.461 1.575 1.575 0 01.458 1.113A1.585 1.585 0 019.19 5.12l-1.092 1.1a.727.727 0 00-.157.793.727.727 0 00.667.448.716.716 0 00.51-.213l1.092-1.1a3.029 3.029 0 00.88-2.141 3.049 3.049 0 00-.88-2.142A3.003 3.003 0 008.086.98a2.985 2.985 0 00-2.125.886v.002z" _fill="#fff"/>'
  }
})
